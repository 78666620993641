.loading-wrapper {
position: fixed;
z-index: 1;
left: 0;
top: 0;
width: 100%;
height: 100%;
overflow: auto;
background-color: rgb(0,0,0);
background-color: rgba(0,0,0,0.4);
  }

.pos {
    position: relative;
      left: 50%;
      top: 50%;
      width: 150px;
  }