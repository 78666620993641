.fileupload-area {
    max-width: 100% !important;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    height: 80px;
    border: 2px dashed #4C82A8;
    padding: 8px 16px 8px 8px;
    border-radius: 5px;
    cursor: pointer;
    -moz-box-flex: 0;
    flex-grow: 0;
}

.wpop-upload-wrapper {
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-flex: 1;
    flex-grow: 1;
}

.wpop-file-types {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 200px;
}

.wpop-upload-text {
    margin-left: 10px;
}

.wpop-list-margin {
    margin-bottom: 10px !important;
    margin-top: 10px !important;
}
